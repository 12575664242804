import api from '../../../services/api';
import { Organization } from '../../../types/Organization';
import { ResponseData } from '../../../types/response/ResponseData';
import ResponseError from '../../../types/response/ResponseError';
import { AppThunkAction, AppThunkDispatch } from '../../thunk';
import {
    getOrganizationsSucceeded,
    getOrganizationsFailed,
    enableOrganizationStarted,
    enableOrganizationSucceeded,
    enableOrganizationFailed,
    clearEnableOrganization,
    deleteOrganizationStarted,
    deleteOrganizationSucceeded,
    deleteOrganizationFailed,
    clearDeleteOrganization,
} from './organizationsSlice';

const listOrganizations = (): AppThunkAction => async (dispatch: AppThunkDispatch) => {
    try {
        const response = await api.get<ResponseData<Organization[]>>('organizations');

        dispatch(getOrganizationsSucceeded(response.data));
    } catch (error) {
        dispatch(getOrganizationsFailed(error as ResponseError));
    }
};

const enableOrganization =
    (organizationId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(enableOrganizationStarted());

        try {
            await api.post(`organizations/${organizationId}/features/checkout/enable`, {});

            dispatch(enableOrganizationSucceeded());
        } catch (error) {
            dispatch(enableOrganizationFailed(error as ResponseError));
        }
    };

const deleteOrganization =
    (organizationId: string, organizationName: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(deleteOrganizationStarted());

        try {
            await api.del(`organizations/${organizationId}`);

            dispatch(deleteOrganizationSucceeded(organizationName));
        } catch (error) {
            dispatch(deleteOrganizationFailed(error as ResponseError));
        }
    };

export default {
    listOrganizations,
    enableOrganization,
    deleteOrganization,
    clearDeleteOrganization,
    clearEnableOrganization,
};
