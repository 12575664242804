import React, { useEffect, useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { useAppState } from '../../store/appstate';
import { Links, Notification, NotificationResult } from '../../types/account/Notification';
import accountActions from '../../store/account/actions';
import { NotificationRow } from '../../types/account/NotificationRow';
import Money from '../../components/atoms/Money/Money';
import CardHeader from '../../components/atoms/Card/CardHeader';
import Card from '../../components/atoms/Card/Card';
import CardBody from '../../components/atoms/Card/CardBody';
import { formatDate } from '../../services/helpers/dateTimeFormats';
import Status from '../../components/atoms/Status/Status';
import StatusTypes from '../../types/StatusTypes';
import Accordion from '../../components/atoms/Accordion';
import InvoiceStatus from '../../types/invoice/InvoiceStatus';
import InvoiceState from '../../types/invoice/InvoiceState';
import Spinner from '../../components/atoms/Spinner/Spinner';
import { Modal } from '../../components/atoms/Modal/Modal';
import HeaderContent from '../../components/atoms/Layout/HeaderContent';
import CenterContent from '../../components/atoms/Layout/CenterContent';
import useModal from '../../services/hooks/useModal';
import { AccountState, clearCreditFeeError } from '../../store/account/accountSlice';
import InformationMessage from '../../components/atoms/Message/Information/InformationMessage';
import StickyNote from '../../components/atoms/StickyNote/StickyNote';
import Total from '../../components/atoms/Money/Total';
import { ErrorType } from '../../types/response/ErrorCodes';
import AccountType from '../../types/account/AccountType';
import CreditFees from '../../components/molecules/Account/CreditFees';
import NoneStyleLink from '../../components/atoms/Link/NoneStyleLink';
import StyledLink from '../../components/atoms/Link/StyledLink';
import AccountCustomer from '../../components/molecules/Customer/AccountCustomer';
import { Payment } from '../../types/account/Payments';
import NoTranslateLabel from '../../components/atoms/Label/NoTranslateLabel';
import { LinkData } from '../../types/response/LinkData';
import EmptyHeaderErrorMessageProps from '../../components/molecules/Error/EmptyHeaderErrorMessage';
import CompactStoreLabel from '../../components/atoms/Store/CompactStoreLabel';
import { ConnectedStore } from '../../types/ConnectedStore';
import getStoreInfo from '../../services/helpers/storeHelper';
import { useAppDispatch } from '../../store';
import NotificaitonInformationAndActions from '../../components/molecules/Account/NotificaitonInformationAndActions';
import NotificationRows from '../../components/molecules/Account/NotificationRows';
import NotificationState from '../../components/atoms/NotificationState/NotificationState';
import { Account } from '../../types/account/Account';
import { Refund } from '../../types/account/Refund';
import Flex from '../../components/atoms/Box/Flex';
import { isFee } from '../../services/helpers/notificationRowHelper';

enum AccountHistoryType {
    NOTIFICATION = 'notification',
    PAYMENT = 'payment',
    REFUND = 'refund',
}

interface AccountHistory {
    id: number;
    type: AccountHistoryType;
    date: string;
}
interface AccountDetailProps {
    accountId: string;
    theme: DefaultTheme;
}

const AccountDetails: React.FC<AccountDetailProps> = ({ accountId, theme }: AccountDetailProps) => {
    const { account, isBusy, fetchAccountFailed, error } = useAppState<AccountState>(
        s => s.account
    );

    const creditFeesModal = useModal();

    const [notificationToCredit, setNotificationToCredit] = useState<{
        notification: Notification;
        hasCredit: LinkData | undefined;
        creditableFees: NotificationRow[];
    } | null>(null);

    const availableStores = useAppState<ConnectedStore[]>(s => s.session.availableStores) ?? [];

    const onCloseCreditFeeModal = () => {
        setNotificationToCredit(null);
        creditFeesModal.toggle();
        dispatch(clearCreditFeeError());
    };

    const store = getStoreInfo(account?.storeId.toString() ?? '', availableStores);

    const renderStoreId = (connectedStore: ConnectedStore | undefined) => {
        if (!connectedStore) return <BoldText>{account?.storeId}</BoldText>;

        return <CompactStoreLabel store={connectedStore} />;
    };

    const dispatch = useAppDispatch();

    const getAccountHistory = (a: Account | undefined) => {
        if (a === undefined) return [];

        const notifications = a.notifications.map(
            x =>
                ({
                    date: x.data.notificationDate,
                    type: AccountHistoryType.NOTIFICATION,
                    id: x.data.notificationId,
                } as AccountHistory)
        );

        const payments = a.payments.map(
            x =>
                ({
                    date: x.paymentDate,
                    type: AccountHistoryType.PAYMENT,
                    id: x.transactionId,
                } as AccountHistory)
        );

        const refunds = a.refunds.map(
            x =>
                ({
                    date: x.refundedAt,
                    type: AccountHistoryType.REFUND,
                    id: x.refundId,
                } as AccountHistory)
        );

        return [...payments, ...refunds, ...notifications].sort((x, y) =>
            y.date.localeCompare(x.date)
        );
    };

    const accountHistory = getAccountHistory(account);

    useEffect(() => {
        dispatch(accountActions.getAccount(accountId));
    }, [dispatch, accountId]);

    const invoiceStatus = (notification: Notification) => {
        if (
            notification.paymentStatus === InvoiceStatus.FullyPaid ||
            notification.paymentStatus === InvoiceStatus.PartiallyPaid
        )
            return notification.paymentStatus;

        if (notification.canceled) return InvoiceStatus.Canceled;

        return notification.paymentStatus;
    };

    const openCreditFees = (notification: Notification, links: Links) => {
        const creditableFees = notification.rows.filter(
            (x: NotificationRow) =>
                isFee(x) && (x.feeTransactions || x.transactionId) && !x.credited
        );

        const hasCredit = links.credit;

        setNotificationToCredit({ notification, hasCredit, creditableFees });
        creditFeesModal.toggle();
    };

    const renderHeader = () => {
        return (
            <Row>
                <Col>
                    <HeaderLabel>Account</HeaderLabel>
                    <Content>
                        <BoldText>
                            <NoTranslateLabel>{account?.type}</NoTranslateLabel>
                        </BoldText>
                    </Content>
                </Col>
                {account?.connectedOrders && account?.connectedOrders.length === 1 && (
                    <>
                        <Col>
                            <HeaderLabel>Order</HeaderLabel>
                            <Content>
                                <StyledLink to={`/orders/${account?.connectedOrders[0].orderId}`}>
                                    <BoldText>
                                        {account?.connectedOrders[0].orderNumber || (
                                            <NotSet>Not Set</NotSet>
                                        )}
                                    </BoldText>
                                </StyledLink>
                            </Content>
                        </Col>
                        <Col>
                            <HeaderLabel>Activation</HeaderLabel>
                            <Content>
                                <BoldText>
                                    {account?.connectedOrders[0].purchaseIdentifier}
                                </BoldText>
                            </Content>
                        </Col>
                    </>
                )}

                <Col>
                    <HeaderLabel>Currency</HeaderLabel>
                    <Content>
                        <BoldText>{account?.currency}</BoldText>
                    </Content>
                </Col>

                {account?.storeId && (
                    <Col>
                        <HeaderLabel>Store</HeaderLabel>
                        <Content>
                            <BoldText>{renderStoreId(store)}</BoldText>
                        </Content>
                    </Col>
                )}
            </Row>
        );
    };

    const renderRefund = (refund: Refund | undefined) => {
        if (!refund) return <></>;

        return (
            <React.Fragment key={`refund-${refund.refundId}`}>
                <CardHeader
                    title="Refund"
                    id="refund"
                    information={<>{formatDate(new Date(refund.refundedAt)).yyyyMMdd}</>}
                    hideBorder
                    color={theme.colors.lighterAccent3}
                >
                    <Flex row justifyContent="flex-end">
                        <div />
                        <CardHeaderMoney>
                            <NotificationTotal>
                                <Money>{refund.amount}</Money>
                            </NotificationTotal>
                        </CardHeaderMoney>
                    </Flex>
                </CardHeader>
            </React.Fragment>
        );
    };

    const renderPayment = (payment: Payment | undefined) => {
        if (!payment) return <></>;

        return (
            <React.Fragment key={`payment-${payment.transactionId}`}>
                <CardHeader
                    title="Payment"
                    id="payment"
                    information={<>{formatDate(new Date(payment.paymentDate)).yyyyMMdd}</>}
                    hideBorder
                    color={theme.colors.lighterAccent2}
                >
                    <Flex row justifyContent="flex-end">
                        <div />
                        <CardHeaderMoney>
                            {payment.leftToPay !== null && payment.leftToPay >= 0 && (
                                <LeftToPay>
                                    Left to pay <Money>{payment.leftToPay}</Money>
                                </LeftToPay>
                            )}

                            {payment.leftToPay !== null && payment.leftToPay < 0 && (
                                <LeftToPay>
                                    Overpayed <Money>{Math.abs(payment.leftToPay)}</Money>
                                </LeftToPay>
                            )}

                            <NotificationTotal>
                                <Money>{payment.amount}</Money>
                            </NotificationTotal>
                        </CardHeaderMoney>
                    </Flex>
                </CardHeader>
            </React.Fragment>
        );
    };

    const renderNotification = (
        notification: Notification | undefined,
        links: Links | undefined
    ) => {
        if (!notification || !links) return <></>;

        const notificationTotal = () => {
            switch (account?.type) {
                case AccountType.InterestFree:
                    return notification.secondaryLowestAmountToPay;
                case AccountType.Annuity:
                    return notification.lowestAmountToPay;
                default:
                    return notification.totalAmountToPay;
            }
        };

        const paymentStatus = invoiceStatus(notification);
        const isCreditNote = notification.state === InvoiceState.CreditNote;

        return (
            <React.Fragment key={notification.invoiceNumber}>
                <CardHeader
                    title={isCreditNote ? 'Credit Note' : 'Notification'}
                    information={
                        <InformationTag>
                            <>{formatDate(new Date(notification.notificationDate)).yyyyMMdd}</>{' '}
                            <Tags>
                                {paymentStatus && !isCreditNote && (
                                    <Status status={paymentStatus} type={StatusTypes.Invoice} />
                                )}

                                {notification.state === InvoiceState.FinalReminder && (
                                    <NotificationState
                                        state={notification.state}
                                        canceled={notification.canceled}
                                    />
                                )}

                                {notification.state === InvoiceState.Reminder && (
                                    <NotificationState
                                        state={notification.state}
                                        canceled={notification.canceled}
                                    />
                                )}

                                {notification.state === InvoiceState.Void && (
                                    <NotificationState
                                        state={notification.state}
                                        canceled={notification.canceled}
                                    />
                                )}
                            </Tags>
                        </InformationTag>
                    }
                    hideBorder
                >
                    {!isCreditNote && (
                        <Flex row justifyContent="flex-end">
                            <CardHeaderMoney>
                                <NotificationTotal>
                                    <Money>{notificationTotal()}</Money>
                                </NotificationTotal>
                            </CardHeaderMoney>
                        </Flex>
                    )}
                </CardHeader>

                <CardBody>
                    <NotificationBody>
                        <NotificaitonInformationAndActions
                            notification={notification}
                            links={links}
                            isCreditNote={isCreditNote}
                            openCreditFees={openCreditFees}
                        />
                        <NotificationRows account={account} notification={notification} />
                    </NotificationBody>
                </CardBody>
            </React.Fragment>
        );
    };

    const getPayment = (id: number) => account?.payments.find(x => x.transactionId === id);
    const getNotificationResult = (id: number) =>
        account?.notifications.find(x => x.data.notificationId === id);

    const renderAccountHistory = (object: AccountHistory) => {
        switch (object.type) {
            case AccountHistoryType.PAYMENT:
                return renderPayment(getPayment(object.id));
            case AccountHistoryType.REFUND:
                return renderRefund(account?.refunds.find(x => x.refundId == object.id));
            case AccountHistoryType.NOTIFICATION: {
                const { data, links } = getNotificationResult(object.id) as NotificationResult;
                return renderNotification(data, links);
            }
            default:
                return <></>;
        }
    };

    if (isBusy)
        return (
            <>
                <HeaderContent />
                <CenterContent>
                    <Spinner text="Fetching account..." loading={isBusy} />
                </CenterContent>
            </>
        );

    if (fetchAccountFailed)
        return (
            <EmptyHeaderErrorMessageProps
                error={error}
                keyWord="account"
                errorType={ErrorType.FetchAccount}
            />
        );

    const canBeClicked = (key: string) => !key.includes('payment') && !key.includes('refund');

    const getIndexOfFirstNotification = () =>
        accountHistory.indexOf(
            accountHistory.filter(x => x.type === AccountHistoryType.NOTIFICATION)[0]
        );

    return (
        <>
            <HeaderContent>
                <CenterContent data-testid="account-header">{renderHeader()}</CenterContent>
            </HeaderContent>

            <CenterContent>
                <Container>
                    <AccountInfo>
                        {account && (accountHistory.length > 0 || account.movedToAccountId) ? (
                            <Card hideBorder>
                                <Accordion
                                    headerCanBeClicked={canBeClicked}
                                    expandItemWithIndex={getIndexOfFirstNotification()}
                                >
                                    {account.movedToAccountId && (
                                        <React.Fragment key={account.movedToAccountId}>
                                            <NoneStyleLink
                                                to={`/accounts/${account.movedToAccountId}`}
                                            >
                                                <CardHeader title="Moved" hideBorder>
                                                    <GoToMoved>{`Go to installment account ${account.movedToAccountId}`}</GoToMoved>
                                                </CardHeader>
                                            </NoneStyleLink>
                                        </React.Fragment>
                                    )}
                                    {accountHistory.map((x: AccountHistory) =>
                                        renderAccountHistory(x)
                                    )}
                                </Accordion>
                            </Card>
                        ) : (
                            <InformationMessage
                                message="No notification has been sent out on this account"
                                messageHeader="No notification sent!"
                            />
                        )}
                    </AccountInfo>
                    {account && account.customerDetails && (
                        <RightPanelContainer>
                            <StickyNote sticky padding>
                                <Label>Current debt</Label>
                                <Total>
                                    {account.movedToAccountId ? (
                                        'Moved'
                                    ) : (
                                        <Money>{account.leftToPay}</Money>
                                    )}
                                </Total>{' '}
                                {!account.movedToAccountId && <span>{account.currency}</span>}
                                {account.movedToAccountId && (
                                    <MovedTo>
                                        To{' '}
                                        <StyledLink to={`/accounts/${account.movedToAccountId}`}>
                                            {account.movedToAccountId}
                                        </StyledLink>
                                    </MovedTo>
                                )}
                            </StickyNote>
                            {account.customerDetailsPurged ? (
                                <InformationMessage
                                    messages={[
                                        "We've removed all personal data for this customer in accordance with GDPR.",
                                        'Contact help@walley.se for more information.',
                                    ]}
                                    messageHeader="Personal data removed"
                                />
                            ) : (
                                <AccountCustomer customer={account.customerDetails} />
                            )}
                        </RightPanelContainer>
                    )}

                    {notificationToCredit && creditFeesModal.isShown && (
                        <Modal large isShown={creditFeesModal.isShown} hide={onCloseCreditFeeModal}>
                            <CreditFees
                                notification={notificationToCredit.notification}
                                data={notificationToCredit.creditableFees}
                                creditFeeLink={notificationToCredit.hasCredit}
                                hide={onCloseCreditFeeModal}
                            />
                        </Modal>
                    )}
                </Container>
            </CenterContent>
        </>
    );
};

export default withTheme(AccountDetails) as React.ComponentType<Omit<AccountDetailProps, 'theme'>>;

const InformationTag = styled.div`
    display: flex;
    align-items: center;
`;

const LeftToPay = styled.div`
    color: ${props => props.theme.colors.text.secondary};
`;

const NotSet = styled.span`
    color: ${props => props.theme.colors.text.secondary};
`;

const RightPanelContainer = styled.div`
    grid-area: right;
    background-color: ${props => props.theme.colors.subtle.light};
    padding: 2rem;
    position: relative;
`;

const NotificationBody = styled.div`
    padding: 1.5rem 2rem 2rem 2rem;
    border-bottom: 0.15rem solid ${props => props.theme.colors.card.border};
`;

const NotificationTotal = styled.div`
    font-size: ${props => props.theme.text.size.large};
    color: ${props => props.theme.colors.text.primary};
    align-self: center;
    font-family: ${props => props.theme.text.font.bold};
`;

const Label = styled.div`
    flex: 1;
    margin-right: ${props => props.theme.layout.margin.small};
    font-size: ${props => props.theme.text.size.small};
    color: ${props => props.theme.colors.text.secondary};
`;

const Container = styled.div`
    display: grid;
    grid-template-areas: 'content right';
    grid-template-columns: auto 40rem;
    grid-column-gap: 2rem;
    height: 100vh;
`;

const AccountInfo = styled.div`
    grid-area: content;
    padding-top: 2rem;
    margin-bottom: 2rem;
`;

const Tags = styled.div`
    font-size: ${props => props.theme.text.size.small};
    align-self: center;
    margin-left: 1rem;
`;

const CardHeaderMoney = styled.div`
    display: flex;
    gap: 3rem;
    vertical-align: sub;
    align-self: center;
`;

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: ${props => props.theme.text.size.medium};
`;

const GoToMoved = styled.span`
    align-self: center;
    margin-left: 1.2rem;
    color: ${props => props.theme.colors.primary};
    text-decoration: underline;
    justify-self: flex-end;
`;

const MovedTo = styled.div`
    margin-top: 0.5rem;
    color: ${props => props.theme.colors.primary};
`;

const Col = styled.div`
    padding: 0 ${props => props.theme.layout.padding.medium};
    display: flex;
    flex-direction: column;
    border-right: 0.2rem solid ${props => props.theme.colors.text.subtle};
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
        border-right: 0;
    }
`;

const HeaderLabel = styled.div`
    font-size: ${props => props.theme.text.size.small};
    margin-bottom: 0.3rem;
    color: ${props => props.theme.colors.text.secondary};
`;

const Content = styled.div`
    color: ${props => props.theme.colors.text.primary};
`;

const BoldText = styled.span`
    font-family: ${props => props.theme.text.font.medium};
    margin-right: 2rem;
`;
