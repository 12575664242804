/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Organization } from '../../../types/Organization';
import ResponseError from '../../../types/response/ResponseError';

export interface OrganizationsState {
    organizations: Organization[];
    error: ResponseError;
    isEnablingOrganization: boolean;
    organizationEnabled: boolean;
    enableOrganizationError: ResponseError | undefined;
    isDeletingOrganization: boolean;
    isDeletedOrganization: boolean;
    deleteOrganizationError: ResponseError | undefined;
    deletedOrganization: string;
}

const initialState: OrganizationsState = {
    organizations: [] as Organization[],
    error: {} as ResponseError,
    isEnablingOrganization: false,
    organizationEnabled: false,
    enableOrganizationError: undefined,
    isDeletingOrganization: false,
    isDeletedOrganization: false,
    deleteOrganizationError: undefined,
    deletedOrganization: '',
};

export const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {
        getOrganizationsSucceeded: (state, action: PayloadAction<Organization[]>) => {
            state.organizations = action.payload;
        },
        getOrganizationsFailed: (state, action: PayloadAction<ResponseError>) => {
            state.error = action.payload;
        },
        enableOrganizationStarted: state => {
            state.isEnablingOrganization = true;
        },
        enableOrganizationSucceeded: state => {
            state.organizationEnabled = true;
            state.isEnablingOrganization = false;
        },
        enableOrganizationFailed: (state, action: PayloadAction<ResponseError>) => {
            state.enableOrganizationError = action.payload;
            state.isEnablingOrganization = false;
        },
        clearEnableOrganization: state => {
            state.isEnablingOrganization = false;
            state.organizationEnabled = false;
            state.enableOrganizationError = undefined;
        },
        deleteOrganizationStarted: state => {
            state.isDeletingOrganization = true;
        },
        deleteOrganizationSucceeded: (state, action: PayloadAction<string>) => {
            state.isDeletingOrganization = false;
            state.isDeletedOrganization = true;
            state.deletedOrganization = action.payload;
        },
        deleteOrganizationFailed: (state, action: PayloadAction<ResponseError>) => {
            state.deleteOrganizationError = action.payload;
            state.isDeletingOrganization = false;
        },
        clearDeleteOrganization: state => {
            state.isDeletingOrganization = false;
            state.isDeletedOrganization = false;
            state.deleteOrganizationError = undefined;
            state.deletedOrganization = '';
        },
    },
});

export const {
    getOrganizationsSucceeded,
    getOrganizationsFailed,
    enableOrganizationStarted,
    enableOrganizationSucceeded,
    enableOrganizationFailed,
    clearEnableOrganization,
    deleteOrganizationStarted,
    deleteOrganizationSucceeded,
    deleteOrganizationFailed,
    clearDeleteOrganization,
} = organizationsSlice.actions;

export default organizationsSlice.reducer;
