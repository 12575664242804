import { combineReducers } from 'redux';
import organizationSliceReducer from './organizations/organizationsSlice';
import usersSliceReducer from './users/usersSlice';
import groupsSliceReducer from './groups/groupsSlice';
import apiSecretsSliceReducer from './apiSecrets/apiSecretsSlice';
import webhooksSliceReducer from './webhooks/webhooksSlice';
import storesSliceReducer from './stores/storesSlice';

const adminStateReducer = combineReducers({
    users: usersSliceReducer,
    groups: groupsSliceReducer,
    organizations: organizationSliceReducer,
    apiSecrets: apiSecretsSliceReducer,
    webhooks: webhooksSliceReducer,
    stores: storesSliceReducer,
});

export default adminStateReducer;
