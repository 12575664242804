import React, { useState } from 'react';
import { useAppState } from '../../../store/appstate';
import { ConnectedStore } from '../../../types/ConnectedStore';
import CenterContent from '../../atoms/Layout/CenterContent';
import HeaderContent from '../../atoms/Layout/HeaderContent';
import NotAuthorized from '../NotAuthorized';
import SearchForm from './SearchForm';
import SessionUserPermissons from '../../../types/SessionUserPermissions';
import SearchResults from './SearchResults';
import TableHeaderTypes from '../../../types/tableHeaderType';
import Summaries from './Summaries';
import Tabs from '../../atoms/Tabs/Tabs';
import Flex from '../../atoms/Box/Flex';
import PeriodicReports from './PeriodicReports';
import Button from '../../atoms/Button/Button';
import useModal from '../../../services/hooks/useModal';
import { Modal } from '../../atoms/Modal/Modal';
import GeneratePeriodicReport from './GeneratePeriodicReport';
import usePermissions from '../../../services/hooks/usePermissions';
import { SessionOrganization } from '../../../types/SessionUser';
import Popover, { TourPosition } from '../../atoms/Tour/Popover';

const Settlements: React.FC = () => {
    const availableStores = useAppState<ConnectedStore[]>(s => s.session.availableStores) ?? [];

    const permissions = useAppState<SessionUserPermissons[]>(
        s => s.session.user?.permissions || []
    );

    const organization = useAppState<SessionOrganization | undefined>(
        s => s.session.selectedOrganization
    );

    const { hasWrite, hasRead } = usePermissions([
        `all-all-periodicreportdetails`,
        `${organization?.id}-all-periodicreportdetails`,
    ]);

    const [tabState, setTabState] = useState<string>('settlements');
    const { isShown, toggle } = useModal();

    if (!permissions.some(x => x.operation === 'read' && x.tag.includes('settlementdetails'))) {
        return (
            <>
                <HeaderContent />
                <CenterContent>
                    <NotAuthorized componentName="Reports" />
                </CenterContent>
            </>
        );
    }

    return (
        <>
            <HeaderContent>
                <CenterContent>
                    {tabState === 'settlements' || tabState === 'summaries' ? (
                        <SearchForm availableStores={availableStores} />
                    ) : (
                        <Flex justifyContent="flex-end" height="7.5rem" alignItems="center">
                            {hasWrite && (
                                <Popover
                                    name="ReportTypes"
                                    position={TourPosition.Right}
                                    horizontal={TourPosition.Right}
                                    item={
                                        <Button
                                            primary
                                            large
                                            onClick={() => toggle()}
                                            data-testid="generate-report-button"
                                        >
                                            Generate report
                                        </Button>
                                    }
                                >
                                    <>
                                        <strong>📅 Periodic reports</strong>
                                        <p>
                                            A periodic report collates information from multiple
                                            settlements during a given period into a single file.
                                            The available report types are listed below.
                                        </p>
                                        <p>
                                            <strong>Settlement Summary:</strong> Aggregated data
                                            from all settlements within the specified period.
                                        </p>
                                        <p>
                                            <strong>Transactions:</strong> All transactions,
                                            including those that have not yet settled.
                                        </p>
                                        <p>
                                            <strong>Merged:</strong> All settlement transactions
                                            merged into a single, comprehensive file.
                                        </p>
                                        <p>
                                            <i>
                                                It may take a few minutes before the report is
                                                finished. Please press the button &apos;Refresh
                                                reports&apos; to get the latest status on the
                                                reports.
                                            </i>
                                        </p>
                                        <p>
                                            <i>
                                                There is no restriction on the number of reports
                                                that can be generated. Expired reports are
                                                automatically purged upon reaching their expiration
                                                date.
                                            </i>
                                        </p>
                                    </>
                                </Popover>
                            )}
                        </Flex>
                    )}
                </CenterContent>
            </HeaderContent>

            <CenterContent>
                <Flex column rowGap={'2rem'}>
                    <Tabs
                        tabsList={[
                            { key: 'settlements', text: 'Settlements' },
                            { key: 'summaries', text: 'Summaries' },
                            {
                                key: 'periodicReports',
                                text: 'Periodic reports',
                                isVisible: hasRead,
                            },
                        ]}
                        startTab={'settlements'}
                        onTabChange={setTabState}
                    >
                        <SearchResults
                            key={'settlements'}
                            tableHeaderType={TableHeaderTypes.Settlement}
                            availableStores={availableStores}
                        />

                        <Summaries key={'summaries'} />
                        <PeriodicReports key={'periodicReports'} />
                    </Tabs>
                    <Modal
                        top
                        medium
                        isShown={isShown}
                        hide={() => toggle()}
                        dataTestId="generate-report-modal"
                    >
                        <GeneratePeriodicReport
                            hide={() => toggle()}
                            availableStores={availableStores}
                        />
                    </Modal>
                </Flex>
            </CenterContent>
        </>
    );
};

export default Settlements;
