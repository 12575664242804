import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import FocusLock from 'react-focus-lock';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import { searchValidationConfig } from '../../../types/ValidationTypes';

import { useAppState } from '../../../store/appstate';
import {
    createSearchQueryString,
    parseQuery,
    parseSearchUrl,
} from '../../../services/helpers/queryStringHelpers';
import { parseFilters } from '../../../services/helpers/filterHelpers';

import Term from '../../atoms/Term/Term';
import { ConnectedStore } from '../../../types/ConnectedStore';
import DateRange from '../../atoms/DateRange/DateRange';
import RelativeOperationTypes from '../../../types/RelativeOperation';
import TermCategory from '../../../types/filter/TermCategory';

type Inputs = {
    searchPhrase: string;
    filter: string[];
};

export enum DashboardSearchTerms {
    PLACED_AT = 'placedAt',
    EXPIRES_AT = 'expiresAt',
}

const today = new Date();

const relativeCreatedRanges = [
    { name: 'today', label: 'Today', value: '0d' },
    { name: 'yesterday', label: 'Yesterday', value: '-1d' },
    { name: 'last7days', label: 'Last 7 days', value: '-7d' },
    { name: 'last30days', label: 'Last 30 days', value: '-30d' },
    {
        name: 'custom',
        label: 'Custom',
        value: '-1d',
    },
];

const relativeExpiresRanges = [
    { name: 'today', label: 'Today', value: '0d' },
    { name: 'within7days', label: 'Within 7 days', value: '+7d' },
    { name: 'within30days', label: 'Within 30 days', value: '+30d' },
    {
        name: 'custom',
        label: 'Custom',
        value: '+1d',
    },
];

const dateRanges = [
    {
        maxDate: today,
        label: 'Created',
        SearchTerm: DashboardSearchTerms.PLACED_AT,
        ranges: relativeCreatedRanges,
        relativeLabel: { type: 'Last', operation: RelativeOperationTypes.SUBTRACT },
    },
    {
        maxDate: undefined,
        label: 'Expires',
        SearchTerm: DashboardSearchTerms.EXPIRES_AT,
        ranges: relativeExpiresRanges,
        relativeLabel: { type: 'Within', operation: RelativeOperationTypes.ADD },
    },
];

interface SearchProps {
    availableStores: ConnectedStore[];
}

const DashboardSearchForm: React.FC<SearchProps> = ({ availableStores }: SearchProps) => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm<Inputs>();
    const { search, pathname } = useLocation();
    const isSearching = useAppState<boolean>(s => s.report.settlement.isSearching);
    const { sort, query, filterMap } = parseSearchUrl(search);

    const [filter, setFilter] = useState<Map<string, string[]>>(new Map());
    const [searchChange, setSearchChange] = useState(false);

    const currentQuery = parseQuery(query, false);
    const SEARCH_PHRASE = 'searchPhrase';

    const makeSearch = (q: string) => {
        const newQuery = encodeURI(
            createSearchQueryString(parseQuery(q, false), filter, sort, '', [])
        );

        navigate(`${pathname}?${newQuery}`);
    };

    const onSubmit = (form: Inputs) => {
        makeSearch(form.searchPhrase);
    };

    const handleFilterChange = (termKey: string | undefined, termFilter: string[]) => {
        if (!termKey) return;
        setFilter(filter.set(termKey, termFilter));
        makeSearch(getValues('searchPhrase'));
    };

    const storeTerm = React.useMemo(
        () =>
            availableStores
                .filter(x => x.active)
                .map(store => {
                    return {
                        value: store.id,
                        count: 0,
                    };
                }),
        [availableStores]
    );

    const status = [
        { value: 'notactivated', count: 0 },
        { value: 'activated', count: 0 },
        { value: 'closed', count: 0 },
        { value: 'returned', count: 0 },
        { value: 'expired', count: 0 },
        { value: 'partactivated', count: 0 },
        { value: 'verificationneeded', count: 0 },
        { value: 'onhold', count: 0 },
    ];

    const currencies = [
        { value: 'sek', count: 0 },
        { value: 'nok', count: 0 },
        { value: 'dkk', count: 0 },
        { value: 'eur', count: 0 },
    ];

    const renderTerms = () => {
        return (
            <Terms>
                {dateRanges.map(x => (
                    <DateRange
                        key={x.label}
                        maxDate={x.maxDate}
                        label={x.label}
                        onDateChange={handleFilterChange}
                        initialValue={parseFilters(x.SearchTerm, filterMap)}
                        dateRangeType={x.SearchTerm}
                        relativeRanges={x.ranges}
                        relativeLabel={x.relativeLabel}
                    />
                ))}
                <Term
                    category={TermCategory.Order}
                    key="storeId"
                    term={{ key: 'storeId', values: storeTerm }}
                    onTermFilterChange={handleFilterChange}
                    currentFilters={parseFilters('storeId', filterMap)}
                    showSearchbar={['storeId']}
                    availableStores={availableStores}
                    limitShow={500}
                />
                <Term
                    category={TermCategory.Order}
                    key="status"
                    term={{ key: 'status', values: status }}
                    onTermFilterChange={handleFilterChange}
                    currentFilters={parseFilters('status', filterMap)}
                    availableStores={availableStores}
                />
                <Term
                    category={TermCategory.Order}
                    key="currency"
                    term={{ key: 'currency', values: currencies }}
                    onTermFilterChange={handleFilterChange}
                    currentFilters={parseFilters('currency', filterMap)}
                />
            </Terms>
        );
    };

    const searchPhrase = register(SEARCH_PHRASE, searchValidationConfig);

    useEffect(() => {
        if (searchChange) {
            const phrase = getValues('searchPhrase');

            makeSearch(phrase);
            setSearchChange(false);
        }
    }, [searchChange, filter]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
                <SearchContainer>
                    <InputWrapper>
                        <FocusLock disabled={!isSearching}>
                            <Input
                                data-testid="search-input"
                                type="text"
                                name={searchPhrase.name}
                                defaultValue={currentQuery}
                                setRef={searchPhrase.ref}
                                placeholder="What are you looking for?"
                                onChange={searchPhrase.onChange}
                                id="settlement-search"
                            />
                        </FocusLock>
                    </InputWrapper>
                    <Button type="submit" primary large>
                        Show
                    </Button>
                </SearchContainer>
            </form>

            {renderTerms()}

            {errors.searchPhrase && (
                <p className="error-message" role="alert">
                    {errors.searchPhrase?.message}
                </p>
            )}
        </>
    );
};

const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
`;

const Terms = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
`;

const InputWrapper = styled.div`
    width: 40rem;
    margin-right: ${props => props.theme.layout.padding.medium};
`;

export default DashboardSearchForm;
