import store from '../..';
import api from '../../../services/api';
import { ResponseData } from '../../../types/response/ResponseData';
import { UserGroup } from '../../../types/UserGroup';
import { AppThunkAction, AppThunkDispatch } from '../../thunk';
import { User } from '../../../types/User';
import { PatchOperation } from '../../../types/requests/PatchOperation';
import ResponseError from '../../../types/response/ResponseError';
import {
    groupSearchStarted,
    groupSearchSucceeded,
    groupSearchFailed,
    groupPageStarted,
    getGroupDetailsStarted,
    getGroupDetailsSucceeded,
    getGroupDetailsFailed,
    getOrganizationPermissionsStarted,
    getOrganizationPermissionsSucceeded,
    getOrganizationPermissionsFailed,
    groupPermissionsToggleStarted,
    groupPermissionsToggleSucceeded,
    patchGroupFailed,
    groupStoreAdded,
    groupStoreRemoved,
    deleteGroupStarted,
    deleteGroupSucceeded,
    deleteGroupFailed,
    createGroupStarted,
    createGroupSucceeded,
    createGroupFailed,
    getGroupListUsersStarted,
    getGroupListUsersSucceeded,
    getGroupListUsersFailed,
    addUserToGroupStarted,
    addUserToGroupSucceeded,
    addUserToGroupFailed,
    removeUserFromGroupStarted,
    removeUserFromGroupSucceeded,
    removeUserFromGroupFailed,
} from './groupsSlice';

const defaultPageSize = 30;

const listGroups =
    (organizationId: string, pageSize: number = defaultPageSize): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(groupSearchStarted(organizationId));

        if (!organizationId) {
            return;
        }

        try {
            const response = await api.get<ResponseData<UserGroup[]>>(
                `organizations/${organizationId}/groups`
            );

            dispatch(groupSearchSucceeded(response));
        } catch (error) {
            dispatch(groupSearchFailed(error as ResponseError));
        }
    };

const requestGroupsPage =
    (link: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(groupPageStarted());
        try {
            const response = await api.get<ResponseData<UserGroup[]>>(link);

            dispatch(groupSearchSucceeded(response));
        } catch (error) {
            dispatch(groupSearchFailed(error as ResponseError));
        }
    };

const getGroup =
    (organizationId: string, groupId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(getGroupDetailsStarted());

        try {
            const response = await api.get<ResponseData<UserGroup>>(
                `/organizations/${organizationId}/groups/${groupId}`
            );
            dispatch(getGroupDetailsSucceeded(response.data));
        } catch {
            dispatch(getGroupDetailsFailed());
        }
    };

const getOrganizationPermissions =
    (organization: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(getOrganizationPermissionsStarted());

        if (!organization) {
            return;
        }

        try {
            const response = await api.get<ResponseData<Record<string, string[]>>>(
                `/organizations/${organization}/permissions`
            );

            dispatch(getOrganizationPermissionsSucceeded(response.data));
        } catch {
            dispatch(getOrganizationPermissionsFailed());
        }
    };

const patchGroup = async (
    organizationId: string,
    dispatch: AppThunkDispatch,
    operations: PatchOperation[]
) => {
    const groupId = store.getState().admin.groups.group.id;

    try {
        await api.patch<PatchOperation[]>(
            `/organizations/${organizationId}/groups/${groupId}`,
            operations
        );
    } catch (error) {
        dispatch(patchGroupFailed(error as ResponseError));
    }
};

const toggleGroupPermissions =
    (
        organizationId: string,
        permissions: string[],
        operation: string,
        isChecked: boolean
    ): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        const currentPermissions = store.getState().admin.groups.group?.permissions;
        const { availablePermissions } = store.getState().admin.groups;
        const groupId = store.getState().admin.groups.group.id;

        const operations = permissions
            .filter((p: string) => {
                if (availablePermissions && availablePermissions[p])
                    return availablePermissions[p].includes(operation);
                return false;
            })
            .reduce((result: PatchOperation[], p: string) => {
                const index = currentPermissions.indexOf(`${operation}-${p}`);
                if (isChecked && index === -1) {
                    result.push({
                        op: 'add',
                        path: '/permissions/-',
                        value: `${operation}-${p}`,
                    });
                }

                if (index > -1) {
                    result.push({
                        op: 'remove',
                        path: `/permissions/${index}`,
                        value: undefined,
                    });
                }

                return result;
            }, [] as PatchOperation[])
            .sort((a, b) => {
                const numberA = a.path.substring(a.path.lastIndexOf('/') + 1);
                const numberB = b.path.substring(b.path.lastIndexOf('/') + 1);

                return Number(numberB) - Number(numberA);
            });

        dispatch(groupPermissionsToggleStarted(operations));
        try {
            const response = (await api.patch<PatchOperation[]>(
                `/organizations/${organizationId}/groups/${groupId}`,
                operations
            )) as ResponseData<UserGroup>;
            dispatch(groupPermissionsToggleSucceeded(response));
        } catch (error) {
            dispatch(patchGroupFailed(error as ResponseError));
        }
    };

const toggleStore =
    (organizationId: string, storeId: string, isChecked: boolean): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        const currentStores = store.getState().admin.groups.group?.connectedStores || [];
        const index = currentStores.indexOf(storeId);
        const operations = [] as PatchOperation[];

        if (isChecked) {
            if (index === -1) {
                dispatch(groupStoreAdded(storeId));
                operations.push({ op: 'add', path: '/connectedStores/-', value: storeId });
            }
        } else if (index > -1) {
            dispatch(groupStoreRemoved(storeId));
            operations.push({ op: 'remove', path: `/connectedStores/${index}`, value: undefined });
        }

        await patchGroup(organizationId, dispatch, operations);
    };

const deleteGroup =
    (organization: string | undefined, groupId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(deleteGroupStarted());

        try {
            await api.del(`organizations/${organization}/groups/${groupId}`);
            dispatch(deleteGroupSucceeded(groupId));
        } catch (error) {
            dispatch(deleteGroupFailed(error as ResponseError));
        }
    };

const createGroup =
    (organizationId: string, name: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(createGroupStarted(name));

        try {
            const result = await api.post<{ name: string }>(
                `organizations/${organizationId}/groups/`,
                {
                    name,
                }
            );
            dispatch(createGroupSucceeded(result));
            await dispatch(listGroups(organizationId || ''));
        } catch (error) {
            dispatch(createGroupFailed(error as ResponseError));
        }
    };

const listUsers =
    (organizationId: string, groupId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(getGroupListUsersStarted({ organization: organizationId, groupId }));

        try {
            const result = await api.get<ResponseData<User[]>>(
                `/organizations/${organizationId}/groups/${groupId}/users`
            );
            dispatch(getGroupListUsersSucceeded(result));
        } catch (error) {
            dispatch(getGroupListUsersFailed(error as ResponseError));
        }
    };

const addUserToGroup =
    (organization: string, userId: string, group: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        const user =
            store.getState().admin.users.userSearchResults.data.find(usr => {
                return usr.id === userId;
            }) || ({} as User);
        dispatch(addUserToGroupStarted({ organizationId: organization, userId, groupId: group }));
        try {
            const response = await api.post<{ group: string }>(
                `organizations/${organization}/users/${userId}/groups`,
                {
                    group,
                }
            );
            dispatch(addUserToGroupSucceeded({ response, user }));
        } catch (error) {
            dispatch(addUserToGroupFailed(error as ResponseError));
            window.console.log(error);
        }
    };

const removeUserFromGroup =
    (organization: string, userId: string, groupId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(removeUserFromGroupStarted({ organizationId: organization, userId, groupId }));

        try {
            await api.del(`organizations/${organization}/users/${userId}/groups/${groupId}`);
            dispatch(removeUserFromGroupSucceeded());
        } catch (error) {
            dispatch(removeUserFromGroupFailed(error as ResponseError));
        }
    };

export default {
    listGroups,
    requestGroupsPage,
    getGroup,
    getOrganizationPermissions,
    toggleGroupPermissions,
    deleteGroup,
    createGroup,
    listUsers,
    addUserToGroup,
    removeUserFromGroup,
    toggleStore,
};
