/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
    AxiosRequestConfig,
    AxiosResponse,
    CancelToken,
    RawAxiosRequestHeaders,
} from 'axios';
import { AnyAction } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import axiosRetry from 'axios-retry';
import { RequestData } from '../types/RequestData';
import store from '../store';
import { config } from '../config';
import { PostResponseData } from '../types/response/PostResponseData';
import ResponseError from '../types/response/ResponseError';
import { LinkData } from '../types/response/LinkData';
import Logger from './logging/logger';

const HTTP_REQUEST_TIMEOUT_IN_MILLISECONDS = 20_000;

const performRequest = async <T>(
    path: string,
    method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH',
    body?: RequestData,
    notProcessingAction?: (show: boolean) => AnyAction,
    cancelToken?: CancelToken,
    api?: string,
    additionalHeaders?: RawAxiosRequestHeaders,
    additionalRetries?: boolean
): Promise<T> => {
    let axiosResponse = {} as AxiosResponse<T>;
    let status = 0;

    const parsedPath = path.startsWith('/') ? path.substring(1) : path;
    const url = `${api ?? config.ApiHost}/${parsedPath}`;
    const correlationId = uuidv4();

    const request = {
        method,
        url,
        timeout: HTTP_REQUEST_TIMEOUT_IN_MILLISECONDS,
        headers: {
            'X-CorrelationId': correlationId,
            'Walley-CorrelationId': correlationId,
            CorrelationId: correlationId,
        },
        cancelToken,
    } as AxiosRequestConfig;

    if (!request.headers) throw ResponseError.requestMissingHeaders();

    if ((method === 'POST' || method === 'PUT' || method === 'PATCH') && body) {
        request.data = body;
        request.headers['Content-Type'] =
            method === 'PATCH' ? 'application/json-patch+json' : 'application/json';
    }

    const { token, expiryTime } = store.getState().session;
    if (token && Date.now() > expiryTime) throw ResponseError.fromUnauthenticatedException(401);

    request.headers.Authorization = `Bearer ${token}`;

    if (method === 'GET' && !url.includes('?')) {
        axiosRetry(axios, {
            retryDelay: retryCount => {
                return retryCount * 1000;
            },
            retries: additionalRetries ? 6 : 3,
            shouldResetTimeout: additionalRetries,
            retryCondition: x => x.response?.status === 404 || x.response?.status === 403,
        });
    }

    if (additionalHeaders) {
        request.headers = {
            ...request.headers,
            ...additionalHeaders,
        };
    }

    try {
        Logger.information('Request initialized', { Url: request.url }, correlationId);
        axiosResponse = await axios(request);
    } catch (error: any) {
        if (axios.isCancel(error) || error.message === 'Request aborted') {
            throw ResponseError.fromCanceledError(error.message ?? '');
        }

        axiosResponse = error.response;

        if (!axiosResponse) {
            Logger.error(
                'Request failed with no response',
                {
                    Url: request.url,
                },
                correlationId
            );

            // if request fails because of, for example, a timeout, we will not get an response.
            throw ResponseError.fromConnectionError(error);
        }

        status = axiosResponse?.status ?? 0;

        const log = getLogger(status);

        log(
            'Request failed',
            {
                Url: request.url,
                StatusCode: status,
                ResponseData: JSON.stringify(axiosResponse.data),
            },
            correlationId
        );

        if (status >= 400) {
            const parseError = (data: any, statusCode: number) => {
                switch (statusCode) {
                    case 400:
                        return ResponseError.fromBadRequestException(data);
                    case 401:
                        return ResponseError.fromUnauthenticatedException(statusCode);
                    case 403:
                        return ResponseError.fromUnauthorizedException(statusCode);
                    case 404:
                        return ResponseError.fromNotFoundException(statusCode);
                    case 409:
                        return ResponseError.fromHandledException(data, statusCode);
                    case 422:
                        return ResponseError.fromHandledException(data, statusCode);
                    case 900:
                        return ResponseError.fromHandledException(data, statusCode);
                    default:
                        return ResponseError.fromUnhandledException(data, statusCode);
                }
            };

            throw parseError(error.response.data, status);
        }
    }

    status = axiosResponse?.status ?? 0;

    let result: T;

    try {
        result = axiosResponse.data as T;
    } finally {
        // store.dispatch(sessionActions.isFetchingData(false));
    }

    if (status === 202 && notProcessingAction) {
        notProcessingAction(true);
        throw new Error();
    }

    if (status !== 204) {
        if (!result && method !== 'DELETE') {
            throw new Error(
                `Response contains no data after executing ${method} request to ${url}`
            );
        }
    }

    return result;
};

const getLogger = (status: number) => {
    switch (status) {
        case 400:
            return Logger.information;
        case 401:
            return Logger.none;
        case 409:
        case 422:
            return Logger.warning;
        default:
            return Logger.error;
    }
};

const get = <T>(
    url: string,
    cancelToken?: CancelToken,
    headers?: RawAxiosRequestHeaders,
    additionalRetries?: boolean,
    api?: string
) => {
    return performRequest<T>(
        url,
        'GET',
        null,
        undefined,
        cancelToken,
        api,
        headers,
        additionalRetries
    );
};

const post = <TReq>(url: string, request: TReq, api?: string): Promise<PostResponseData> => {
    return postWithResponseType<TReq, PostResponseData>(url, request, api);
};

const postWithResponseType = <TReq, TRes>(
    url: string,
    request: TReq,
    api?: string
): Promise<TRes> => {
    return performRequest<TRes>(url, 'POST', request, undefined, undefined, api);
};

const del = <T>(url: string, api?: string) => {
    return performRequest<T>(url, 'DELETE', undefined, undefined, undefined, api);
};

const put = <TReq>(url: string, request: TReq) => {
    return performRequest(url, 'PUT', request);
};

const patch = <TReq>(url: string, request: TReq, api?: string) => {
    return performRequest(url, 'PATCH', request, undefined, undefined, api);
};

const callLink = <TReq, TRes>(
    notProcessingAction: (show: boolean) => AnyAction,
    link: LinkData,
    body?: TReq
) => {
    return performRequest<TRes>(link.href, link.method, body, notProcessingAction);
};

export default { get, post, del, put, patch, callLink, postWithResponseType };
