import { ReportColumnType } from '../../types/report/ReportColumnType';

const transactionsReportColumns = {
    Name: ReportColumnType.CustomerFullName,
    Partner: ReportColumnType.PartnerName,
    'Order Number': ReportColumnType.OrderNumber,
    'Invoice Number': ReportColumnType.InvoiceNumber,
    'Transaction Type': ReportColumnType.TransactionType,
    Amount: ReportColumnType.Amount,
    Date: ReportColumnType.CreatedOn,
    'Transaction Date': ReportColumnType.TransactionDate,
    'Product Code': ReportColumnType.ProductCode,
    'Is Manual': ReportColumnType.IsManual,
};

export default transactionsReportColumns as Record<string, ReportColumnType>;
