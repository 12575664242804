import React, { useEffect } from 'react';

import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CenterContent from '../../components/atoms/Layout/CenterContent';

import HeaderContent from '../../components/atoms/Layout/HeaderContent';

import PaylinkDetailsHeader from '../../components/molecules/Paylink/PaylinkDetailsHeader';
import Card from '../../components/atoms/Card/Card';
import PaylinkTimeline from '../../components/molecules/Paylink/PaylinkTimeline';
import PaylinkDetailsColumns from '../../components/molecules/HeaderColumns/PaylinkDetailsColumns';
import CardBody from '../../components/atoms/Card/CardBody';
import CardHeader from '../../components/atoms/Card/CardHeader';
import CheckoutSessionRow from '../../types/paylink/CheckoutSessionRow';
import useModal from '../../services/hooks/useModal';
import { Modal } from '../../components/atoms/Modal/Modal';
import SendPaylinkModal from '../../components/molecules/Paylink/SendPaylinkModal';
import { CheckoutState, clearCheckout } from '../../store/checkout/checkoutSlice';
import { useAppState } from '../../store/appstate';
import checkoutActions from '../../store/checkout/actions';
import Spinner from '../../components/atoms/Spinner/Spinner';
import { ErrorType } from '../../types/response/ErrorCodes';
import EmptyHeaderErrorMessage from '../../components/molecules/Error/EmptyHeaderErrorMessage';
import usePermissions from '../../services/hooks/usePermissions';
import EmptyHeader from '../../components/atoms/Header/EmptyHeader';
import NotAuthorized from '../../components/molecules/NotAuthorized';
import Button from '../../components/atoms/Button/Button';
import { useAppDispatch } from '../../store';
import Table from '../../components/atoms/Table/Table';
import AbandonPaylinkModal from '../../components/molecules/Paylink/AbandonPaylinkModal';
import useLogTimeSpentOnPage from '../../services/hooks/useLogTimeSpentOnPage';
import ShowQrModal from '../../components/molecules/Paylink/ShowQrModal';
import { LockedModal } from '../../components/atoms/Modal/LockedModal';

interface LocationState {
    state: {
        sendModalOpen: boolean;
    };
}

const PaylinkDetails: React.FC = () => {
    const { isShown: isAbandonConfirmShown, toggle: toggleAbandonConfirm } = useModal();
    const { isShown, toggle } = useModal();
    const { isShown: isQrShown, toggle: toggleQr } = useModal();
    const { state } = useLocation() as LocationState;

    const navigation = useNavigate();
    const { privateId = '' } = useParams();
    const dispatch = useAppDispatch();

    const { isGettingPaylinkDetails, paylink, paylinkLinks, getPaylinkDetailsFailed } =
        useAppState<CheckoutState>(s => s.checkout);

    if (paylink) document.title = `Merchant Hub - Pay Link - ${paylink.reference ?? paylink.id}`;

    useLogTimeSpentOnPage('paylinkDetails');

    const { hasRead } = usePermissions(paylink?.tags);

    const onClick = () => {
        toggle();
    };

    const shortenPaylink = (value: string) => {
        const split = value.split('/');

        return split[split.length - 1];
    };

    const onHide = () => {
        dispatch(clearCheckout());
        toggle();
        navigation('', { state: { sendModalOpen: false } });
        toggle();
    };

    const onHideAbandon = () => {
        dispatch(clearCheckout());
        toggleAbandonConfirm();
    };

    const onClickDelete = () => {
        toggleAbandonConfirm();
    };

    const onClickCopy = () => {
        navigator.clipboard.writeText(paylink.paylink);
    };

    const onClickOpen = () => {
        window.open(paylink.paylink, '_blank');
    };

    useEffect(() => {
        if (state && state.sendModalOpen) {
            navigation('', { state: { sendModalOpen: false } });
            toggle();
        }
    }, []);

    useEffect(() => {
        dispatch(checkoutActions.getPaylinkDetails(privateId));
    }, [dispatch]);

    if (isGettingPaylinkDetails) {
        return (
            <>
                <HeaderContent />
                <CenterContent>
                    <Spinner
                        text="Fetching Pay Link details..."
                        loading={isGettingPaylinkDetails}
                        size={10}
                    />
                </CenterContent>
            </>
        );
    }

    if (getPaylinkDetailsFailed) {
        return (
            <EmptyHeaderErrorMessage
                error={getPaylinkDetailsFailed}
                keyWord="checkout"
                errorType={ErrorType.PaylinkDetails}
            />
        );
    }

    return hasRead && paylink ? (
        <>
            <HeaderContent>
                <PaylinkDetailsHeader
                    paylink={paylink}
                    paylinkQrCode={paylinkLinks?.qrCode?.href}
                    onClick={onClick}
                    onClickShowQr={toggleQr}
                    onClickDelete={onClickDelete}
                    onClickCopy={onClickCopy}
                    onClickOpen={onClickOpen}
                />
            </HeaderContent>

            <CenterContent>
                <RefreshButton onClick={() => window.location.reload()}>
                    Refresh Pay Link
                </RefreshButton>
                <Content>
                    <Card>
                        <CardHeader information={<span>Specification</span>} />
                        <CardBody>
                            <Table<CheckoutSessionRow>
                                data={paylink.items ?? []}
                                columns={PaylinkDetailsColumns}
                            />
                        </CardBody>
                    </Card>

                    <PaylinkTimeline events={paylink.events ?? []} />
                </Content>
            </CenterContent>
            <Modal small focusLock isShown={isShown} hide={() => onHide()}>
                <SendPaylinkModal
                    privateId={privateId}
                    paylinkTotal={paylink.total}
                    hide={() => onHide()}
                    orderNumber={paylink.reference}
                    currency={paylink.currency}
                    paylink={paylink.paylink ?? ''}
                    paylinkSlug={shortenPaylink(paylink.paylink)}
                />
            </Modal>

            <LockedModal medium focusLock isShown={isQrShown} hide={() => toggleQr()}>
                <ShowQrModal
                    hide={() => toggleQr()}
                    qrPayLinkHref={paylinkLinks?.qrCode?.href ?? ''}
                />
            </LockedModal>

            <Modal small focusLock isShown={isAbandonConfirmShown} hide={() => onHideAbandon()}>
                <AbandonPaylinkModal privateId={privateId} hide={() => onHideAbandon()} />
            </Modal>
        </>
    ) : (
        <>
            <HeaderContent>
                <EmptyHeader />
            </HeaderContent>
            <CenterContent>
                <NotAuthorized componentName="Pay Link Details" />
            </CenterContent>
        </>
    );
};

export default PaylinkDetails;

const RefreshButton = styled(Button)`
    margin: 2rem 0 0 0;
`;

const Content = styled.div`
    margin: 1rem 0 5rem 0;
`;
