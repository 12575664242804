import React, { useEffect, useMemo, useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';

import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import PencilLineIcon from 'remixicon-react/PencilLineIcon';
import ArrowGoBackLineIcon from 'remixicon-react/ArrowGoBackLineIcon';

import AddLineIcon from 'remixicon-react/AddLineIcon';
import PriceTag3LineIcon from 'remixicon-react/PriceTag3LineIcon';
import MoneyDollarCircleLineIcon from 'remixicon-react/MoneyDollarCircleLineIcon';

import orderActions from '../../../store/order/actions';
import { Order } from '../../../types/order/Order';
import { OrderArticleRow } from '../../../types/order/OrderArticleRow';
import { useAppState } from '../../../store/appstate';
import ResponseError from '../../../types/response/ResponseError';
import { LinkData } from '../../../types/response/LinkData';

import Money from '../../atoms/Money/Money';
import Button from '../../atoms/Button/Button';
import Spinner from '../../atoms/Spinner/Spinner';
import IconButton from '../../atoms/Button/IconButton';
import DateTime from '../../atoms/DateTime/DateTime';
import VAT from '../../atoms/Money/VAT';

import useExpandable from '../../../services/hooks/useExpandable';
import ErrorMessage from '../../atoms/Message/Error/ErrorMessage';

import EditOrderRow from './EditOrderRow';
import AddNewArticleRow from './AddNewArticleRow';
import AddDiscountOrFee from './AddDiscountOrFee';
import ReturnOrderRow from './ReturnOrderRow';
import Card from '../../atoms/Card/Card';
import CardBody from '../../atoms/Card/CardBody';
import OrderArticleStatus from '../../../types/order/OrderArticleStatus';
import { formatNumber, numberAsDiff } from '../../../services/helpers/numberFormats';
import LayoutContainer from '../../atoms/Modal/LayoutContainer';
import LayoutPosition from '../../atoms/Modal/LayoutPosition';
import StickyNote from '../../atoms/StickyNote/StickyNote';
import Label from '../../atoms/Label/Label';
import ModalContainer from '../../atoms/Modal/ModalContainer';
import Status from '../../atoms/Status/Status';
import HeaderTitle from '../../atoms/Modal/HeaderTitle';
import FooterButtonContainer from '../../atoms/Modal/FooterButtonContainer';
import CardFooter from '../../atoms/Card/CardFooter';
import OrderRowType from '../../../types/order/OrderRowTypes';
import StatusTypes from '../../../types/StatusTypes';
import { ErrorType } from '../../../types/response/ErrorCodes';
import { getDefaultVat } from '../../../services/helpers/commonVat';
import IconUndo from '../../atoms/Icons/IconUndo';
import { useAppDispatch } from '../../../store';
import Table from '../../atoms/Table/Table';
import { ColumnDef } from '@tanstack/react-table';

interface Props {
    order: Order;
    editLink?: LinkData;
    hide: () => void;
    data: OrderArticleRow[];
    theme: DefaultTheme;
}

const EditOrder: React.FC<Props> = ({ order, data, hide, editLink, theme }: Props) => {
    const dispatch = useAppDispatch();
    const addProductExpandable = useExpandable();
    const isBusy = useAppState<boolean>(s => s.order.isBusy);
    const error = useAppState<ResponseError | undefined>(s => s.order.error);
    const isUpdated = useAppState<boolean>(s => s.order.isUpdated);
    const [dataToShow, setDataToShow] = useState([...data]);
    const [changes, setChanges] = useState(new Map<string, Map<string, string | number>>());
    const [newRowType, setNewRowType] = useState('');
    const [anyReturned, setAnyReturned] = useState(false);

    const [originalChunkValue] = useState(
        [...data].reduce((prev, curr) => prev + curr.price * curr.quantity, 0)
    );

    const newChunkValue = useMemo(() => {
        return dataToShow
            .filter(x => !x.isRemoved)
            .reduce((prev, curr) => prev + curr.price * curr.quantity, 0);
    }, [dataToShow]);

    const newChunkValueDifference = useMemo(() => {
        return originalChunkValue - newChunkValue;
    }, [newChunkValue, originalChunkValue]);

    const isEdited =
        dataToShow.some(x => x.isAdded) ||
        dataToShow.some(x => x.isRemoved) ||
        dataToShow.some(x => x.isChanged);

    const isEntireOrderReturnable = dataToShow.some(x => x.status === OrderArticleStatus.Activated);

    const handleEditData = (newValue: OrderArticleRow) => {
        const index = dataToShow.findIndex(r => r.rowId === newValue.rowId);
        const newDataToShow = [...dataToShow];

        if (index !== -1) {
            newDataToShow[index] = newValue;
        } else {
            newDataToShow.push(newValue);
        }

        setDataToShow(newDataToShow);
    };

    const handleCancelEdit = (row: OrderArticleRow) => {
        let rowCopy: OrderArticleRow = { ...row };
        rowCopy = { ...row, isExpanded: false, isChanged: row.isAdded || row.isChanged };
        handleEditData(rowCopy);
    };

    const handleReturnAll = () => {
        const returnedValues = [] as OrderArticleRow[];
        data.forEach((article: OrderArticleRow) => {
            const articleClone = {
                ...article,
                isRemoved: !anyReturned,
                isCrossedOver: !anyReturned,
            };
            returnedValues.push(articleClone);
        });
        setAnyReturned(isReturned => !isReturned);
        setDataToShow(returnedValues.concat(dataToShow.filter(row => row.isAdded)));
    };

    const handleEditOrder = () => {
        if (!editLink) return;

        dispatch(orderActions.updateOrder(editLink, dataToShow, changes));
    };

    const handleEditProduct = (
        newRow: OrderArticleRow,
        productData: Map<string, string | number>
    ) => {
        if (newRow.isAdded) {
            handleEditData({ ...newRow, isChanged: false });
            return;
        }
        setChanges(changes.set(newRow.rowId, productData));
        handleEditData(newRow);
    };

    const handleRemoveProduct = (row: OrderArticleRow) => {
        if (row.isAdded) {
            setDataToShow([...dataToShow.filter(x => x.rowId !== row.rowId)]);
            return;
        }

        const originalRow = data.find(x => x.rowId === row.rowId) as OrderArticleRow;

        handleEditData({
            ...originalRow,
            isRemoved: !row.isRemoved,
            isCrossedOver: !row.isCrossedOver,
        });
    };

    const handleAddProduct = (newRow: OrderArticleRow) => {
        handleEditData(newRow);
    };

    const defaultVat = getDefaultVat(dataToShow);

    const expandableContent = (row: OrderArticleRow) => {
        if (row.isReturnableExpanded) {
            const newData = { ...row, isCrossedOver: false };

            return (
                <ReturnOrderRow
                    data={newData}
                    handleCancel={handleCancelEdit}
                    handleReturnProduct={handleEditProduct}
                />
            );
        }

        const originalRow = { ...(data.find(x => x.rowId === row.rowId) as OrderArticleRow) };

        return (
            <EditOrderRow
                data={row}
                originalRow={originalRow}
                handleCancel={handleCancelEdit}
                handleEditProduct={handleEditProduct}
            />
        );
    };

    const toggleExpandableReturn = (row: OrderArticleRow) => {
        const value = !(row.isReturnableExpanded && row.isExpanded);
        if (row.quantity > 1) {
            handleEditData({ ...row, isReturnableExpanded: value, isExpanded: value });
            if (addProductExpandable.isShown) {
                addProductExpandable.toggle();
            }
        } else {
            handleEditData({
                ...row,
                isCrossedOver: true,
                isRemoved: true,
            });
        }
        setAnyReturned(true);
    };

    const undoReturn = (row: OrderArticleRow) => {
        const originalRow = data.find(x => x.rowId === row.rowId);

        if (originalRow) {
            handleEditData({
                ...row,
                isExpanded: false,
                isCrossedOver: false,
                isRemoved: false,
                isChanged: false,
                quantity: originalRow?.quantity || 0,
            });
        }
        setAnyReturned(false);
    };

    const toggleExpandableEdit = (row: OrderArticleRow) => {
        if (addProductExpandable.isShown) {
            addProductExpandable.toggle();
        }
        handleEditData({ ...row, isExpanded: !row.isExpanded });
    };

    const handleNewRow = (type: string) => {
        if (type === newRowType || !addProductExpandable.isShown) {
            const expandedReturn = dataToShow.find(r => r.isExpanded);
            addProductExpandable.toggle();
            if (expandedReturn) {
                handleEditData({
                    ...expandedReturn,
                    isExpanded: false,
                    isReturnableExpanded: false,
                });
            }
        }
        setNewRowType(type);
    };

    const renderActivatedRowButtons = (row: OrderArticleRow) => {
        if (row.status !== OrderArticleStatus.Activated) return <></>;

        if (row.isChanged && !row.isRemoved)
            return (
                <>
                    <IconButton
                        onClick={() => undoReturn(row)}
                        hidden={row.isExpanded}
                        dataTestId="activated-undo-return-button"
                        color="secondary"
                        disabled={dataToShow.some(r => r.isExpanded)}
                    >
                        <IconUndo />
                    </IconButton>
                </>
            );

        return (
            <>
                <IconButton
                    onClick={() => {
                        toggleExpandableReturn(row);
                    }}
                    disabled={dataToShow.some(r => r.isExpanded)}
                    color="primary"
                    hidden={row.isRemoved}
                    title="Return"
                    dataTestId="activated-return-button"
                >
                    <ArrowGoBackLineIcon size={theme.icon.size.xsmall} />
                </IconButton>
                <IconButton
                    onClick={() => {
                        undoReturn(row);
                    }}
                    color="secondary"
                    title="Undo return"
                    hidden={!row.isRemoved}
                >
                    <IconUndo />
                </IconButton>
            </>
        );
    };

    const renderNotActivatedRowButtons = (row: OrderArticleRow) => {
        if (row.status === OrderArticleStatus.Activated) return <></>;

        return (
            <>
                <IconButton
                    onClick={() => handleRemoveProduct(row)}
                    hidden={row.isExpanded}
                    dataTestId="not-activated-return-button"
                    color={row.isRemoved ? 'secondary' : 'primary'}
                    disabled={dataToShow.some(r => r.isExpanded)}
                >
                    {row.isRemoved ? <IconUndo /> : <CloseLineIcon size={theme.icon.size.normal} />}
                </IconButton>
                <IconButton
                    onClick={() => {
                        toggleExpandableEdit(row);
                    }}
                    color="secondary"
                    hidden={row.isRemoved}
                    dataTestId="not-activated-edit-button"
                    disabled={dataToShow.some(r => r.isExpanded)}
                >
                    <PencilLineIcon size={theme.icon.size.normal} />
                </IconButton>
            </>
        );
    };

    const renderReturnAllBtn = () => {
        if (!anyReturned) {
            return (
                <IconWrapperReturnAll>
                    <ArrowGoBackLineIcon size={theme.icon.size.xsmall} />
                    Return all
                </IconWrapperReturnAll>
            );
        }
        return (
            <IconWrapperReturnAll>
                <IconUndo />
                Undo
            </IconWrapperReturnAll>
        );
    };

    const checkQuantity = (currRow: OrderArticleRow, originalRow: OrderArticleRow) =>
        !currRow.isCrossedOver && currRow.isChanged && currRow.quantity < originalRow.quantity;

    const columns: ColumnDef<OrderArticleRow>[] = [
        {
            header: 'Description',
            accessorKey: 'description',
            cell: (props: { row: { original: OrderArticleRow } }) => {
                return <span>{props.row.original.description ?? '-'}</span>;
            },
        },
        {
            header: 'Article number',
            accessorKey: 'articleNumber',
        },
        {
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            header: 'Quantity',
            accessorKey: 'quantity',
            cell: (props: { row: { original: OrderArticleRow } }) => {
                const originalRow =
                    data.find(x => x.rowId === props.row.original.rowId) || ({} as OrderArticleRow);
                const qntyLessThanOriginal = checkQuantity(props.row.original, originalRow);

                if (qntyLessThanOriginal) {
                    return (
                        <span>{`${props.row.original.quantity} of ${originalRow.quantity}`}</span>
                    );
                }
                if (props.row.original.isCrossedOver) {
                    return <span>{originalRow.quantity ?? 0}</span>;
                }
                return <span>{props.row.original.quantity ?? 0}</span>;
            },
        },
        {
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            header: 'Unit price',
            accessorKey: 'price',
            cell: (props: { row: { original: OrderArticleRow } }) => {
                return <Money>{props.row.original.price ?? 0}</Money>;
            },
        },
        {
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            header: 'VAT',
            accessorKey: 'vatRate',
            cell: (props: { row: { original: OrderArticleRow } }) => {
                return (
                    <VAT
                        amount={props.row.original.vatAmount ?? 0}
                        rate={props.row.original.vatRate ?? 0}
                    />
                );
            },
        },
        {
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            id: 'buttons',
            header: () => {
                if (isEntireOrderReturnable) {
                    return (
                        <ActionButton
                            onClick={handleReturnAll}
                            disabled={dataToShow.some(r => r.isExpanded)}
                        >
                            {renderReturnAllBtn()}
                        </ActionButton>
                    );
                }

                return <span>Actions</span>;
            },
            cell: (props: { row: { original: OrderArticleRow } }) => {
                return (
                    <IconButtonContainer>
                        {renderActivatedRowButtons(props.row.original)}
                        {renderNotActivatedRowButtons(props.row.original)}
                    </IconButtonContainer>
                );
            },
        },
    ];

    useEffect(() => {
        if (isUpdated) hide();
    }, [hide, isUpdated]);

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>Edit articles</HeaderTitle>
                <OrderNumber>{order.orderNumber}</OrderNumber>
                <Status type={StatusTypes.Order} status={order.status} />
            </ModalContainer>

            <ModalContainer position="content">
                <LayoutContainer>
                    <LayoutPosition position="left">
                        <Card>
                            <CardBody>
                                <Table<OrderArticleRow>
                                    expandable={{
                                        expandableContent,
                                    }}
                                    data={dataToShow}
                                    columns={columns}
                                    newRowProperties={{
                                        toggleAddNewRow: addProductExpandable.toggle,
                                        newRowAdded: addProductExpandable.isShown,
                                        newRowContent:
                                            newRowType === OrderRowType.Article ? (
                                                <AddNewArticleRow
                                                    handleClose={addProductExpandable.toggle}
                                                    handleAddProduct={handleAddProduct}
                                                    defaultVat={defaultVat}
                                                />
                                            ) : (
                                                <AddDiscountOrFee
                                                    handleClose={addProductExpandable.toggle}
                                                    handleAddProduct={handleAddProduct}
                                                    type={newRowType}
                                                    defaultVat={defaultVat}
                                                />
                                            ),
                                    }}
                                />
                            </CardBody>

                            <CardFooter>
                                <ActionButton onClick={() => handleNewRow(OrderRowType.Article)}>
                                    <AddLineIcon size={theme.icon.size.small} />
                                    Add article
                                </ActionButton>
                                <ActionButton onClick={() => handleNewRow(OrderRowType.Fee)}>
                                    <MoneyDollarCircleLineIcon size={theme.icon.size.small} />
                                    Add fee
                                </ActionButton>
                                <ActionButton onClick={() => handleNewRow(OrderRowType.Discount)}>
                                    <PriceTag3LineIcon size={theme.icon.size.small} />
                                    Add discount
                                </ActionButton>
                            </CardFooter>
                        </Card>
                    </LayoutPosition>
                    <LayoutPosition position="right">
                        <StickyNote padding>
                            <Label>New value</Label>
                            <Total data-testid="currentValue">
                                {formatNumber(newChunkValue)}
                            </Total>{' '}
                            <span>{order.currency}</span>
                            <Label>Difference</Label>
                            <Money testId="currentDifference">
                                {numberAsDiff(newChunkValueDifference)}
                            </Money>
                        </StickyNote>

                        {data[0]?.purchaseId && (
                            <>
                                <Label>Purchase identifier</Label>
                                <div>{data[0].purchaseId}</div>
                            </>
                        )}

                        <Label>Payment method</Label>
                        <div>{order.paymentMethod}</div>
                        <Label>Created</Label>
                        <DateTime>{order.placedAt}</DateTime>
                    </LayoutPosition>
                </LayoutContainer>
            </ModalContainer>

            {error && (
                <ModalContainer position="error">
                    <ErrorMessage error={error} errorHeader={ErrorType.OrderUpdate} />
                </ModalContainer>
            )}

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    <Button large disabled={isBusy} onClick={hide}>
                        Cancel
                    </Button>
                    <Button primary large disabled={isBusy || !isEdited} onClick={handleEditOrder}>
                        {isBusy ? (
                            <Spinner color={theme.colors.light} size={8} loading />
                        ) : (
                            <span>Update Order</span>
                        )}
                    </Button>
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

const OrderNumber = styled.span`
    font-family: ${props => props.theme.text.font.medium};
    margin-right: 2rem;
`;

const IconWrapperReturnAll = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ActionButton = styled(Button)`
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0 1rem 0 1rem;
    outline: none;
    &:focus {
        outline: none;
    }

    svg {
        width: 1.3rem;
        vertical-align: bottom;
        padding-right: 0.4rem;
    }
`;

const IconButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const Total = styled.span`
    font-family: ${props => props.theme.text.type.bold};
    font-size: 3rem;
`;

export default withTheme(EditOrder) as React.ComponentType<Omit<Props, 'theme'>>;

