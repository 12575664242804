import React, { useEffect, useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import SearchEye from 'remixicon-react/SearchEyeLineIcon';
import { TypedColumn } from '../../../types/TypedColumn';
import { Order } from '../../../types/order/Order';
import { ConnectedStore } from '../../../types/ConnectedStore';
import { useAppState } from '../../../store/appstate';
import {
    checkMatchByLowercase,
    getOrderHeaderColumns,
} from '../../../services/helpers/orderColumnFilter';
import TableHeaderTypes from '../../../types/tableHeaderType';
import HeaderContent from '../../atoms/Layout/HeaderContent';
import CenterContent from '../../atoms/Layout/CenterContent';
import SearchForm from './SearchForm';
import SearchResults from './SearchResults';
import userSettingsActions from '../../../store/userSettings/actions';
import SidebarMenu from '../../atoms/Menu/SidebarMenu';
import Button from '../../atoms/Button/Button';
import ShortcutMenu from './ShortcutMenu';
import { useAppDispatch } from '../../../store';
import { generateOrderTanHeaders } from '../HeaderColumns/OrderColumnHeaders';
import { ColumnDef } from '@tanstack/react-table';
import { UserSettingsState } from '../../../store/userSettings/userSettingsSlice';
import OrderSearchTabType from '../../../types/order/OrderTabType';
import { parseSearchUrl } from '../../../services/helpers/queryStringHelpers';
import { useLocation } from 'react-router-dom';

interface SearchProps {
    availableStores: ConnectedStore[];
    theme: DefaultTheme;
}

const Search: React.FC<SearchProps> = ({ availableStores, theme }: SearchProps) => {
    const { search: queryString } = useLocation();
    const { columns, tab: tabFromQuery } = parseSearchUrl(queryString);

    const allOrderColumnHeaders = generateOrderTanHeaders(availableStores);
    const { orderAccountSearchTab: tabFromSettings } = useAppState<UserSettingsState>(
        s => s.userSettings
    );

    const getDefaultTab = (savedTab: string) => {
        if (tabFromQuery === OrderSearchTabType.Orders) return OrderSearchTabType.Orders;
        if (tabFromQuery === OrderSearchTabType.Invoices) return OrderSearchTabType.Invoices;

        if (!Object.values(OrderSearchTabType).includes(savedTab as OrderSearchTabType))
            return OrderSearchTabType.Orders;

        return savedTab;
    };

    const dispatch = useAppDispatch();

    const [toggleShortcutMenu, setToggleShortcutMenu] = useState(false);
    const [resetTableSelects, setResetTableSelects] = useState<boolean>(true);
    const [tab, setTab] = useState(getDefaultTab(tabFromSettings ?? OrderSearchTabType.Orders));

    const sessionColumns = useAppState<string[]>(s => s.userSettings.orderColumnFilters) ?? [];

    const defaultColumns = [
        'Created',
        'Order number',
        'Store',
        'Customer name',
        'Status',
        'Payment method',
        'Order value',
    ];

    const getColumns = () => {
        const startingColumns = allOrderColumnHeaders.filter(x =>
            defaultColumns.includes(x.header as string)
        );

        const queryFilterColumns = columns.map(x =>
            allOrderColumnHeaders.find(
                y => y.header === x || checkMatchByLowercase(y.header as string, x)
            )
        ) as TypedColumn<Order>[];

        const isInvalidArray = queryFilterColumns.some(x => x === undefined);

        const tableColumns =
            queryFilterColumns.length !== 0 && !isInvalidArray
                ? queryFilterColumns
                : startingColumns;

        const allColumnsAsStrings = allOrderColumnHeaders.map(x => x.header);
        const isSessionColumnsValid = sessionColumns.every(col =>
            allColumnsAsStrings.includes(col)
        );

        if (
            tableColumns.length !== sessionColumns.length &&
            columns &&
            columns.length === 0 &&
            isSessionColumnsValid
        ) {
            const savedColumns = getOrderHeaderColumns(sessionColumns, allOrderColumnHeaders);
            return savedColumns;
        }

        return tableColumns;
    };

    const tableColumns = getColumns();

    useEffect(() => {
        if (sessionColumns.length === 0) {
            dispatch(userSettingsActions.saveColumnFilters(defaultColumns, TableHeaderTypes.Order));
        }
    }, [sessionColumns]);

    return (
        <Page>
            <HeaderContent>
                <CenterContent>
                    <Container>
                        <div>
                            <SearchForm setResetTableSelects={setResetTableSelects} />
                        </div>
                        <Shortcuts>
                            <ShortCutToggleButton
                                width="12rem"
                                onClick={() => setToggleShortcutMenu(true)}
                            >
                                <SearchEye size={theme.icon.size.small} /> Shortcuts
                            </ShortCutToggleButton>
                        </Shortcuts>
                    </Container>
                </CenterContent>
            </HeaderContent>

            {toggleShortcutMenu && (
                <SidebarMenu
                    isVisible={toggleShortcutMenu}
                    toggle={() => setToggleShortcutMenu(false)}
                >
                    <ShortcutMenu />
                </SidebarMenu>
            )}

            <CenterContent>
                <Content>
                    <SearchResults
                        availableStores={availableStores}
                        allColumnHeaders={allOrderColumnHeaders}
                        defaultColumns={defaultColumns}
                        tableHeaderType={TableHeaderTypes.Order}
                        tableColumns={tableColumns as ColumnDef<Order>[]}
                        setTableColumns={_ => {}}
                        savedColumns={sessionColumns}
                        resetTableSelects={resetTableSelects}
                        setResetTableSelects={setResetTableSelects}
                        setTab={setTab}
                        tab={tab}
                    />
                </Content>
            </CenterContent>
        </Page>
    );
};

const Page = styled.div`
    padding-bottom: 4rem;
`;

const Content = styled.div`
    margin: 2rem 0;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

const Shortcuts = styled.div`
    margin-left: auto;
`;

const ShortCutToggleButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

export default withTheme(Search) as React.ComponentType<Omit<SearchProps, 'theme'>>;
