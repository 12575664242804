import { config } from '../../config';
import api from '../../services/api';
import CheckoutSession from '../../types/paylink/CheckoutSession';
import { CheckoutInitRequest } from '../../types/requests/CheckoutInitRequest';
import SendPaylinkRequest from '../../types/requests/sendPaylinkRequest';
import { CheckoutDetailsResponseData } from '../../types/response/CheckoutDetailsResponseData';
import CheckoutInitResponse from '../../types/response/CheckoutInitResponse';
import { PagingResponseData } from '../../types/response/PagingResponseData';
import { PostResponseData } from '../../types/response/PostResponseData';
import ResponseError from '../../types/response/ResponseError';
import { AppThunkAction, AppThunkDispatch } from '../thunk';
import {
    initCheckoutStarted,
    initCheckoutSucceeded,
    initCheckoutFailed,
    sendPaylinkStarted,
    sendPaylinkSucceeded,
    sendPaylinkFailed,
    searchPaylinkStarted,
    searchPaylinkSucceeded,
    searchPaylinkFailed,
    getPaylinkDetailsStarted,
    getPaylinkDetailsSucceeded,
    getPaylinkDetailsFailed,
    clearPaylinkDetails,
    abandonPaylinkStarted,
    abandonPaylinkSucceeded,
    abandonPaylinkFailed,
    initPurchaseSucceeded,
} from './checkoutSlice';

export enum CheckoutSessionType {
    CHECKOUT = 'CHECKOUT',
    PURCHASE = 'PURCHASE',
}

const initCheckoutSession =
    (checkoutData: CheckoutInitRequest): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(initCheckoutStarted());

        try {
            const response = await api.postWithResponseType<
                CheckoutInitRequest,
                CheckoutInitResponse
            >('/checkouts', checkoutData, config.WalleyApiHost);

            dispatch(initCheckoutSucceeded(response));
        } catch (error) {
            dispatch(initCheckoutFailed(error as ResponseError));
        }
    };

const initPurchaseSession =
    (checkoutData: CheckoutInitRequest): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(initCheckoutStarted());

        try {
            const response = await api.postWithResponseType<CheckoutInitRequest, PostResponseData>(
                '/purchases',
                checkoutData
            );

            dispatch(initPurchaseSucceeded(response));
        } catch (error) {
            dispatch(initCheckoutFailed(error as ResponseError));
        }
    };

const sendPaylink =
    (privatePaylinkId: string, mobilePhoneNumber: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(sendPaylinkStarted());

        try {
            await api.post<SendPaylinkRequest>(
                `/checkouts/${privatePaylinkId}/paylink`,
                {
                    destination: {
                        mobilePhoneNumber,
                    },
                },
                config.WalleyApiHost
            );

            dispatch(sendPaylinkSucceeded());
        } catch (error) {
            dispatch(sendPaylinkFailed(error as ResponseError));
        }
    };

const abandonPaylink =
    (privatePaylinkId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(abandonPaylinkStarted());

        try {
            await api.del(`/checkouts/${privatePaylinkId}`, config.WalleyApiHost);

            dispatch(abandonPaylinkSucceeded());
        } catch (error) {
            dispatch(abandonPaylinkFailed(error as ResponseError));
        }
    };

const search =
    (searchQueryString: string | undefined): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        let query = searchQueryString?.replace('?', '');
        if (query === '') query = 'query=&page=1&perPage=30';

        dispatch(searchPaylinkStarted());

        try {
            const response = await api.get<PagingResponseData<CheckoutSession[]>>(
                `/checkouts?${query}`
            );

            dispatch(searchPaylinkSucceeded(response));
        } catch (error) {
            dispatch(searchPaylinkFailed(error as ResponseError));
        }
    };

const getPaylinkDetails =
    (id: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(clearPaylinkDetails());
        dispatch(getPaylinkDetailsStarted());

        try {
            const response = await api.get<CheckoutDetailsResponseData>(
                `/checkouts/${id}`,
                undefined,
                undefined,
                true
            );

            dispatch(getPaylinkDetailsSucceeded(response));
        } catch (error) {
            dispatch(getPaylinkDetailsFailed(error as ResponseError));
        }
    };

export default {
    initCheckoutSession,
    sendPaylink,
    search,
    getPaylinkDetails,
    abandonPaylink,
    initPurchaseSession,
};
