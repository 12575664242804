import { DatePeriod } from '../../components/atoms/CalendarDateRange/DateRangePicker';
import { ResponseData } from '../response/ResponseData';

export enum PeriodicReportStatus {
    GENERATING = 'Generating',
    FAILED = 'Failed',
    DONE = 'Done',
}
export enum PeriodicReportType {
    SETTLEMENT_SUMMARY = 'SettlementSummary',
    TRANSACTIONS = 'Transactions',
    MERGED = 'Merged',
}

export interface PeriodicReport {
    id: string;
    name: string;
    type: PeriodicReportType;
    status: PeriodicReportStatus;
    period: {
        start: string;
        end: string;
    };
    createdBy: string;
    expirationDate: string;
    creationDate: string;
}
export interface GenerateReportFormData {
    name: string;
    type: PeriodicReportType;
    period: DatePeriod;
    stores: string[];
    columns: Record<string, string> | null;
}

export type PeriodicReportResponse = ResponseData<PeriodicReport[]>;
export type PeriodicReportDownloadTokenRespone = ResponseData<string>;
