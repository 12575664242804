import { ColumnDef } from '@tanstack/react-table';
import transparentize from 'polished/lib/color/transparentize';
import React, { useState } from 'react';
import InformationLineIcon from 'remixicon-react/InformationLineIcon';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { formatDate } from '../../../services/helpers/dateTimeFormats';
import { Account } from '../../../types/account/Account';
import AccountType from '../../../types/account/AccountType';
import { Notification } from '../../../types/account/Notification';
import { NotificationRow, NotificationRowTypes } from '../../../types/account/NotificationRow';
import Card from '../../atoms/Card/Card';
import CardFooter from '../../atoms/Card/CardFooter';
import CheckBox from '../../atoms/Checkbox/Checkbox';
import Money from '../../atoms/Money/Money';
import SubTotal from '../../atoms/Money/SubTotal';
import Table from '../../atoms/Table/Table';

interface Props {
    account?: Account;
    notification: Notification;
    theme: DefaultTheme;
}

const NotificationRows: React.FC<Props> = ({ account, notification, theme }: Props) => {
    const columns: ColumnDef<NotificationRow>[] = [
        {
            header: 'Type',
            accessorKey: 'type',
            cell: (props: { row: { original: NotificationRow } }) => {
                return <div>{props.row.original.type ?? '-'}</div>;
            },
        },
        {
            header: 'Description',
            accessorKey: 'description',
            cell: (props: { row: { original: NotificationRow } }) => {
                return <div>{props.row.original.description ?? '-'}</div>;
            },
        },
        {
            header: 'Order no',
            accessorKey: 'orderNumber',
            cell: (props: { row: { original: NotificationRow } }) => {
                if (props.row.original.type === NotificationRowTypes.TEXTROW) return <>&nbsp;</>;

                return <div>{props.row.original.orderNumber ?? '-'}</div>;
            },
        },
        {
            header: 'Created',
            accessorKey: 'transactionDate',
            cell: (props: { row: { original: NotificationRow } }) => {
                if (props.row.original.type === NotificationRowTypes.TEXTROW) return <>&nbsp;</>;

                return (
                    <div>
                        {props.row.original.transactionDate
                            ? formatDate(new Date(props.row.original.transactionDate)).yyyyMMdd
                            : '-'}
                    </div>
                );
            },
        },
        {
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            header: 'Quantity',
            accessorKey: 'quantity',
            cell: (props: { row: { original: NotificationRow } }) => {
                if (props.row.original.type === NotificationRowTypes.TEXTROW) return <>&nbsp;</>;

                return (
                    <div>
                        {props.row.original.quantity === 0 ? '-' : props.row.original.quantity}
                    </div>
                );
            },
        },
        {
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            header: 'Unit price',
            accessorKey: 'price',
            cell: (props: { row: { original: NotificationRow } }) => {
                if (props.row.original.type === NotificationRowTypes.TEXTROW) return <>&nbsp;</>;

                return (
                    <div>
                        {props.row.original.price === 0 && props.row.original.type !== 'Purchase'
                            ? '-'
                            : props.row.original.price}
                    </div>
                );
            },
        },
        {
            meta: {
                style: {
                    textAlign: 'right',
                    rowColor: 'black',
                },
            },
            header: 'Amount',
            accessorKey: 'totalPrice',
            cell: (props: { row: { original: NotificationRow } }) => {
                if (props.row.original.type === NotificationRowTypes.TEXTROW) return <>&nbsp;</>;

                return props.row.original.isCreditedOnThisNotification ? (
                    <Money isCrossedOut>{props.row.original.totalPrice ?? 0}</Money>
                ) : (
                    <Money>{props.row.original.totalPrice ?? 0}</Money>
                );
            },
        },
    ];
    const [showTextRows, setShowTextRows] = useState<boolean>(false);
    const hasTextRows = notification.rows.some(x => x.type === 'TextRow');
    const rows = showTextRows
        ? notification.rows
        : notification.rows.filter(x => x.type !== 'TextRow');

    const notificationFeeRowStatus = (row: NotificationRow) => {
        if (row.isCreditedOnThisNotification) return transparentize(0.85, theme.colors.accent3);
        return 'transparent';
    };

    const hasNewlyCreditedRowOnNotification = notification.rows.some(
        x => x.isCreditedOnThisNotification
    );

    return (
        <>
            {hasTextRows && (
                <CheckBox onChange={e => setShowTextRows(e.target.checked)}>Show textrows</CheckBox>
            )}

            <Card>
                <Table<NotificationRow>
                    getRowStyles={row => ({
                        background: notificationFeeRowStatus(row),
                    })}
                    data={rows}
                    columns={columns}
                />

                <CardFooterBlock hasMultipleItems={hasNewlyCreditedRowOnNotification}>
                    {hasNewlyCreditedRowOnNotification && (
                        <LeftBlockContent>
                            <InformationLineIcon
                                size={theme.icon.size.small}
                                color={theme.colors.accent3}
                            />
                            <NotificationCreditedInfo>
                                Fees that have been credited will be removed on a future invoice.
                            </NotificationCreditedInfo>
                        </LeftBlockContent>
                    )}

                    <RightBlockContent>
                        <BlockItem>
                            <SubTotal
                                label="Total amount to pay"
                                amount={notification.totalAmountToPay}
                            />
                        </BlockItem>
                        {account?.type === AccountType.InterestFree && (
                            <BlockItem>
                                <SubTotal
                                    label="Amount to pay according to payment plan"
                                    amount={notification.secondaryLowestAmountToPay}
                                />
                            </BlockItem>
                        )}
                        <SubTotal
                            label="Lowest amount to pay"
                            amount={notification.lowestAmountToPay}
                        />
                    </RightBlockContent>
                </CardFooterBlock>
            </Card>
        </>
    );
};

const BlockItem = styled.div`
    margin-bottom: ${props => props.theme.layout.margin.xsmall};
`;

const RightBlockContent = styled.div`
    flex: none;
    align-self: flex-end;
`;

interface CardFooterBlockProps {
    hasMultipleItems?: boolean;
}

const CardFooterBlock = styled(CardFooter)<CardFooterBlockProps>`
    ${props => props.hasMultipleItems && `justify-content: space-between;`}
`;

const LeftBlockContent = styled.div`
    display: flex;
    gap: 0.5rem;
    justify-self: left;
`;

const NotificationCreditedInfo = styled.div`
    font-size: ${props => props.theme.text.size.medium};
`;

export default withTheme(NotificationRows) as React.ComponentType<Omit<Props, 'theme'>>;
