export enum ReportColumnType {
    CustomerFullName = 'CustomerFullName',
    PartnerName = 'PartnerName',
    OrderNumber = 'OrderNumber',
    InvoiceNumber = 'InvoiceNumber',
    TransactionType = 'TransactionType',
    Amount = 'Amount',
    CreatedOn = 'CreatedOn',
    CreatedOnInPartnerTimeZone = 'CreatedOnInPartnerTimeZone',
    TransactionDate = 'TransactionDate',
    ProductCode = 'ProductCode',
    IsManual = 'IsManual',
    ExternalCustomerId = 'ExternalCustomerId',
    CustomerFirstName = 'CustomerFirstName',
    CustomerLastName = 'CustomerLastName',
    SettlementDate = 'SettlementDate',
    PayoutReference = 'PayoutReference',
    SettlementReference = 'SettlementReference',
    ActionReference = 'ActionReference',
    Period = 'Period',
    SettlementId = 'SettlementId',
    PartnerId = 'PartnerId',
    DebtFromPreviousSettlement = 'DebtFromPreviousSettlement',
    Adjustments = 'Adjustments',
    Purchases = 'Purchases',
    QuantityPurchases = 'QuantityPurchases',
    Returns = 'Returns',
    QuantityReturns = 'QuantityReturns',
    PurchaseAmountAdjustments = 'PurchaseAmountAdjustments',
    QuantityPurchaseAmountAdjustments = 'QuantityPurchaseAmountAdjustments',
    ManualPurchases = 'ManualPurchases',
    QuantityManualPurchases = 'QuantityManualPurchases',
    ManualReturns = 'ManualReturns',
    QuantityManualReturns = 'QuantityManualReturns',
    ManualPurchaseAmountAdjustments = 'ManualPurchaseAmountAdjustments',
    QuantityManualPurchaseAmountAdjustments = 'QuantityManualPurchaseAmountAdjustments',
    ServiceFee = 'ServiceFee',
    TransactionFee = 'TransactionFee',
    AddInvoiceFee = 'AddInvoiceFee',
    VAT = 'VAT',
    Commission = 'Commission',
    Kickback = 'Kickback',
    KickbackPercentage = 'KickbackPercentage',
    DirectPayment = 'DirectPayment',
    NotificationPrintCost = 'NotificationPrintCost',
    CreditedCustomerFeeCost = 'CreditedCustomerFeeCost',
    SettlementAmount = 'SettlementAmount',
}
