import { ReportColumnType } from '../../types/report/ReportColumnType';

const settlementSummaryReportColumns = {
    Period: ReportColumnType.Period,
    SettlementId: ReportColumnType.SettlementId,
    PartnerID: ReportColumnType.PartnerId,
    'Debt From Previous Settlement': ReportColumnType.DebtFromPreviousSettlement,
    Adjustments: ReportColumnType.Adjustments,
    Purchases: ReportColumnType.Purchases,
    'Quantity - Purchases': ReportColumnType.QuantityPurchases,
    Returns: ReportColumnType.Returns,
    'Quantity - Returns': ReportColumnType.QuantityReturns,
    'Purchase Amount Adjustments': ReportColumnType.PurchaseAmountAdjustments,
    'Quantity - Purchase Amount Adjustments': ReportColumnType.QuantityPurchaseAmountAdjustments,
    'Manual purchases': ReportColumnType.ManualPurchases,
    'Quantity - Manual purchases': ReportColumnType.QuantityManualPurchases,
    'Manual returns': ReportColumnType.ManualReturns,
    'Quantity - Manual returns': ReportColumnType.QuantityManualReturns,
    'Manual Purchase Amount Adjustments': ReportColumnType.ManualPurchaseAmountAdjustments,
    'Quantity - Manual Purchase Amount Adjustments':
        ReportColumnType.QuantityManualPurchaseAmountAdjustments,
    'Service fee': ReportColumnType.ServiceFee,
    'Transaction fee': ReportColumnType.TransactionFee,
    'AddInvoice fee': ReportColumnType.AddInvoiceFee,
    VAT: ReportColumnType.VAT,
    Commission: ReportColumnType.Commission,
    Kickback: ReportColumnType.Kickback,
    'Kickback %': ReportColumnType.KickbackPercentage,
    'Direct Payment': ReportColumnType.DirectPayment,
    'Notification Print Cost': ReportColumnType.NotificationPrintCost,
    'Credited Customer Fee Cost': ReportColumnType.CreditedCustomerFeeCost,
    CreatedOn: ReportColumnType.CreatedOn,
    SettlementAmount: ReportColumnType.SettlementAmount,
};

export default settlementSummaryReportColumns as Record<string, ReportColumnType>;
